.kz__canvas {
  position: absolute;
  z-index: 10000;
}

.kz__prompt {
  width: 100%;
  margin: 0px;
  padding: 0px;
  color: #fff;
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.kz__pull {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10000;
}

.kz__img {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  border: 2px solid #fff;
  transform: translate(-225%, -50%);
  cursor: grab;
  position: absolute;
}

@media only screen and (max-width: 600px) {
  .kz__img {
    transform: translate(-50%, -125%);
  }
}

.kz__img:active {
  cursor: grabbing;
}

.kz__icons {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.kz__icons * {
  margin: 0 .5rem 1rem;
  color: #fff;
  z-index: 10000;
  transition: 300ms;
}

.kz__icons *:hover {
opacity: .5;
}